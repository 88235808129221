import { useRoutes } from 'react-router-dom';
import Main from '../pages/Main';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import AboutUs from '../pages/AboutUs';
import Service from '../pages/Service';
import Reference from '../pages/Reference';

export default function Router() {
  const routes = [
    {
      element: <Main />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/iletisim',
          element: <Contact />,
        },
        {
          path: '/hakkimizda',
          element: <AboutUs />,
        },
        {
          path: '/hizmetler',
          element: <Service />,
        },
        {
          path: '/referanslar',
          element: <Reference />,
        },
      ],
    },
  ];

  return useRoutes(authMap(routes));
}

const authMap = (routes) =>
  routes.map((route) => {
    if (route?.children) {
      route.children = authMap(route.children);
    }
    return route;
  });
