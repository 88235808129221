export default function Service() {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        class="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: 'url(images/carousel-2.png)' }}
      >
        <div class="container-fluid page-header-inner py-5">
          <div class="container text-center pb-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">
              Hizmetlerimiz
            </h1>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Room Start --> */}
      <div class="container-xxl py-5" id="service">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title text-center text-primary text-uppercase">
              HİZMETLERİMİZİ
            </h6>
            <h1 class="mb-5">KEŞFEDİN</h1>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/trafo-1.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Saatlik/Günlük Vinç Kiralama</h5>
                  </div>
                  <p class="text-body mb-3">
                    Saatlik veya Günlük İşleriniz için İhtiyaca Uygun Farklı
                    Tonajlı Makineler
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/platform-1.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Sepetli Platform Kiralama</h5>
                  </div>
                  <p class="text-body mb-3">
                    Zirveye Taşımanın Pratik Yolu: <br /> Sepetli Platformlar
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/forklift-1.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Forklift Kiralama</h5>
                  </div>
                  <p class="text-body mb-3">
                    Yüksek Performans, Düşük Maliyet ile İhtiyacınız olan
                    Forkliftler
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-4 mt-3">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/manlift-1.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Makaslı Platform Kiralama</h5>
                  </div>
                  <p class="text-body mb-3">
                    İşlerinizi Yüksekten Yönetin: <br /> Makaslı Platformlarla
                    Yükselin!
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/direk-1.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Tabela/Direk Dikim ve Montaj</h5>
                  </div>
                  <p class="text-body mb-3">
                    Tabela/Direk Dikim ve Montaj İşlerinizde Farklı Metraj ve
                    Tonajlı Araç Seçenekleri
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/makine.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Makine Taşıma Yerleştirme</h5>
                  </div>
                  <p class="text-body mb-3">
                    Makine veya Dükkan/Fabrika Taşıma İşlerinizde Geniş Araç ve
                    Personel Kadrosu
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/prefabrik-1.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Prefabrik Nakliye ve Montaj</h5>
                  </div>
                  <p class="text-body mb-3">
                    Prefabrik Yapılarınızın Nakliye ve Montajı <br />
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/celik-1.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Çelik Konstrüksiyon Montajı</h5>
                  </div>
                  <p class="text-body mb-3">
                    Çelik Konstrüksiyon Montajı veya İnşaat İşlerinizde Daima
                    Yanınızda
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/arac-kurtarma.png"
                    alt=""
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Araç Kurtarma</h5>
                  </div>
                  <p class="text-body mb-3">
                    İstenmeyen Araç Kazalarında Araç Kurtarma ve Nakliye
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Room End --> */}
    </>
  );
}
