export default function AboutUs() {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        class="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: 'url(images/carousel-2.png)' }}
      >
        <div class="container-fluid page-header-inner py-5">
          <div class="container text-center pb-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">
              Hakkımızda
            </h1>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- About Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              <h6 class="section-title text-start text-primary text-uppercase">
                HAKKIMIZDA
              </h6>
              <h2 class="mb-4">HİZMET VİNÇ - PLATFORM</h2>
              <p class="mb-4">
                Hizmet Vinç olarak 10 yılı aşkın süredir sektörde, müşteri
                memnuniyeti ve güven odaklı çalışmalarımız ile sağlam bir yer
                edinmiş bulunmaktayız. Çeşitli endüstriyel ihtiyaçlara yönelik
                çözümler sunan firmamız, tecrübeli personel ve geniş araç
                kadrosuyla 7/24 hizmet vermektedir. Firmamız müşterilerine geniş
                araç filosu sunarak farklı kapasite ve özelliklere sahip
                araçları kapsamlı bir şekilde kullanıma sunmaktadır. Güvenlik
                standartlarına uygun olarak iş sağlığı ve güvenliği ilkelerini
                benimseyen firma, projelerin her aşamasında güvenilir ve etkili
                hizmet sunmaktadır.
              </p>
              <div class="row g-3 pb-4">
                <div class="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                  <div class="border rounded p-1">
                    <div class="border rounded text-center p-4">
                      <i class="fa fa-layer-group fa-2x text-primary mb-2"></i>
                      <h2 class="mb-1" data-toggle="counter-up">
                        10+
                      </h2>
                      <p class="mb-0">Yıllık Tecrübe</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                  <div class="border rounded p-1">
                    <div class="border rounded text-center p-4">
                      <i class="fa fa-truck fa-2x text-primary mb-2"></i>
                      <h2 class="mb-1" data-toggle="counter-up">
                        17+
                      </h2>
                      <p class="mb-0">Araç</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                  <div class="border rounded p-1">
                    <div class="border rounded text-center p-4">
                      <i class="fa fa-users fa-2x text-primary mb-2"></i>
                      <h2 class="mb-1" data-toggle="counter-up">
                        12+
                      </h2>
                      <p class="mb-0">Personel</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src="images/about-1.png"
                    style={{ marginTop: '25%' }}
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src="images/about-2.png"
                  />
                </div>
                <div class="col-8 text-start w-100">
                  <img
                    class="img-fluid rounded w-50 wow zoomIn position-relative"
                    style={{ left: '25%' }}
                    data-wow-delay="0.7s"
                    src="images/about-3.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}
    </>
  );
}
