export default function Home() {
  return (
    <>
      {/* <!-- Carousel Start --> */}
      <div class="container-fluid p-0 mb-5">
        <div
          id="header-carousel"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="w-100" src="images/carousel-1.png" alt="Image" />
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div class="p-3" style={{ maxWidth: '700px' }}>
                  <h1 class="section-title text-uppercase mb-3 animated slideInDown carouselTitle">
                    HİZMET VİNÇ - PLATFORM
                  </h1>
                  <h2 class="display-3 text-white mb-4 animated slideInDown">
                    Kalitenin Öncüsüyüz !
                  </h2>
                  <a
                    href="#service"
                    class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    rel="noopener noreferrer"
                  >
                    HİZMETLERİMİZ
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100" src="images/carousel-2.png" alt="Image" />
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div class="p-3" style={{ maxWidth: '700px' }}>
                  <h1 class="section-title text-uppercase mb-3 animated slideInDown carouselTitle">
                    HİZMET VİNÇ - PLATFORM
                  </h1>
                  <h2 class="display-3 text-white mb-4 animated slideInDown">
                    Şehrin Markasıyız!
                  </h2>
                  <a
                    href="#service"
                    class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    rel="noopener noreferrer"
                  >
                    HİZMETLERİMİZ
                  </a>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100" src="images/carousel-3.png" alt="Image" />
              <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div class="p-3" style={{ maxWidth: '700px' }}>
                  <h1 class="section-title text-uppercase mb-3 animated slideInDown carouselTitle">
                    HİZMET VİNÇ - PLATFORM
                  </h1>
                  <h2 class="display-3 text-white mb-4 animated slideInDown">
                    İnovasyon ve Estetiği Bir Araya Getiriyoruz!
                  </h2>
                  <a
                    href="#service"
                    class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    rel="noopener noreferrer"
                  >
                    HİZMETLERİMİZ
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* <!-- Carousel End --> */}

      {/* <!-- About Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6">
              <h6 class="section-title text-start text-primary text-uppercase">
                HAKKIMIZDA
              </h6>
              <h2 class="mb-4">HİZMET VİNÇ - PLATFORM</h2>
              <p class="mb-4">
                Hizmet Vinç olarak 10 yılı aşkın süredir sektörde, müşteri
                memnuniyeti ve güven odaklı çalışmalarımız ile sağlam bir yer
                edinmiş bulunmaktayız. Çeşitli endüstriyel ihtiyaçlara yönelik
                çözümler sunan firmamız, tecrübeli personel ve geniş araç
                kadrosuyla 7/24 hizmet vermektedir. Firmamız müşterilerine geniş
                araç filosu sunarak farklı kapasite ve özelliklere sahip
                araçları kapsamlı bir şekilde kullanıma sunmaktadır. Güvenlik
                standartlarına uygun olarak iş sağlığı ve güvenliği ilkelerini
                benimseyen firma, projelerin her aşamasında güvenilir ve etkili
                hizmet sunmaktadır.
              </p>
              <div class="row g-3 pb-4">
                <div class="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                  <div class="border rounded p-1">
                    <div class="border rounded text-center p-4">
                      <i class="fa fa-layer-group fa-2x text-primary mb-2"></i>
                      <h2 class="mb-1" data-toggle="counter-up">
                        10+
                      </h2>
                      <p class="mb-0">Yıllık Tecrübe</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                  <div class="border rounded p-1">
                    <div class="border rounded text-center p-4">
                      <i class="fa fa-truck fa-2x text-primary mb-2"></i>
                      <h2 class="mb-1" data-toggle="counter-up">
                        17+
                      </h2>
                      <p class="mb-0">Araç</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                  <div class="border rounded p-1">
                    <div class="border rounded text-center p-4">
                      <i class="fa fa-users fa-2x text-primary mb-2"></i>
                      <h2 class="mb-1" data-toggle="counter-up">
                        12+
                      </h2>
                      <p class="mb-0">Personel</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row g-3">
                <div class="col-6 text-end">
                  <img
                    class="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src="images/about-1.png"
                    style={{ marginTop: '25%' }}
                    alt="platform-foto"
                  />
                </div>
                <div class="col-6 text-start">
                  <img
                    class="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src="images/about-2.png"
                    alt="manlift-foto"
                  />
                </div>
                <div class="col-8 text-start w-100">
                  <img
                    class="img-fluid rounded w-50 wow zoomIn position-relative"
                    style={{ left: '25%' }}
                    data-wow-delay="0.7s"
                    src="images/about-3.png"
                    alt="vinc-foto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Services Start --> */}
      <div class="container-xxl py-5" id="service">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title text-center text-primary text-uppercase">
              HİZMETLERİMİZİ
            </h6>
            <h3 class="mb-5">KEŞFEDİN</h3>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/trafo-1.png"
                    alt="vinc foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Saatlik/Günlük Vinç Kiralama</h5>
                  </div>
                  <p class="text-body mb-3">
                    Saatlik veya Günlük İşleriniz için İhtiyaca Uygun Farklı
                    Tonajlı Makineler
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/platform-1.png"
                    alt="platform foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Sepetli Platform Kiralama</h5>
                  </div>
                  <p class="text-body mb-3">
                    Zirveye Taşımanın Pratik Yolu: <br /> Sepetli Platformlar
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/forklift-1.png"
                    alt="forklift foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Forklift Kiralama</h5>
                  </div>
                  <p class="text-body mb-3">
                    Yüksek Performans, Düşük Maliyet ile İhtiyacınız olan
                    Forkliftler
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-4 mt-3">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/manlift-1.png"
                    alt="manlift foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Makaslı Platform Kiralama</h5>
                  </div>
                  <p class="text-body mb-3">
                    İşlerinizi Yüksekten Yönetin: <br /> Makaslı Platformlarla
                    Yükselin!
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/direk-1.png"
                    alt="vinc foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Tabela/Direk Dikim ve Montaj</h5>
                  </div>
                  <p class="text-body mb-3">
                    Tabela/Direk Dikim ve Montaj İşlerinizde Farklı Metraj ve
                    Tonajlı Araç Seçenekleri
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/makine.png"
                    alt="vinc foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Makine Taşıma Yerleştirme</h5>
                  </div>
                  <p class="text-body mb-3">
                    Makine veya Dükkan/Fabrika Taşıma İşlerinizde Geniş Araç ve
                    Personel Kadrosu
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/prefabrik-1.png"
                    alt="vinc foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Prefabrik Nakliye ve Montaj</h5>
                  </div>
                  <p class="text-body mb-3">
                    Prefabrik Yapılarınızın Nakliye ve Montajı <br />
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/celik-1.png"
                    alt="vinc foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Çelik Konstrüksiyon Montajı</h5>
                  </div>
                  <p class="text-body mb-3">
                    Çelik Konstrüksiyon Montajı veya İnşaat İşlerinizde Daima
                    Yanınızda
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img
                    class="img-fluid"
                    src="images/arac-kurtarma.png"
                    alt="vinc foto"
                  />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="mb-0">Araç Kurtarma</h5>
                  </div>
                  <p class="text-body mb-3">
                    İstenmeyen Araç Kazalarında Araç Kurtarma ve Nakliye
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Services End --> */}

      {/* <!-- Video Start --> */}
      {/* <div class="container-xxl py-5 px-0 wow zoomIn" data-wow-delay="0.1s">
        <div class="row g-0">
          <div class="col-md-6 bg-dark d-flex align-items-center">
            <div class="p-5">
              <h6 class="section-title text-start text-white text-uppercase mb-3">
                YAPIM SÜRECİ
              </h6>
              <h1 class="text-white mb-4">Referans Videomuz</h1>
              <p class="text-white mb-4">
                Müşterilerimizin memnuniyetini yansıtıyor. Estetik, dayanıklılık
                ve profesyonel hizmetlerimiz hakkında gerçek müşteri deneyimleri
                bu videoda yer alıyor. <b>Hizmet Vinç</b>'e neden güvendiklerini
                keşfedin.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <iframe
              style={{ width: '100%', height: '100%' }}
              type="button"
              class="btn-play w-100 h-100"
              src="https://www.youtube.com/embed/8eEX0Tjj9fo?si=KVk7MqidLCaBhm4W"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div> */}

      <div
        class="modal fade"
        id="videoModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content rounded-0">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Youtube Video
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {/* <!-- 16:9 aspect ratio --> */}
              <div class="ratio ratio-16x9">
                <iframe
                  class="embed-responsive-item"
                  src=""
                  id="video"
                  allowfullscreen
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Video Start --> */}

      {/* <!-- Logo Slider --> */}
      <div
        class="text-center wow fadeInUp mt-5"
        data-wow-delay="0.1s"
        id="company-reference"
      >
        <h6 class="section-title text-center text-primary text-uppercase">
          REFERANS
        </h6>
        <h1>FİRMALARIMIZ</h1>
      </div>
      <div class="slider">
        <div class="slide-track">
          <div class="slide">
            <img
              src="/images/biberci.png"
              alt="bibercilogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/tetik.png"
              alt="tetiklogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img src="/images/tcdd.png" alt="tcddlogo" className="slider-img" />
          </div>
          <div class="slide">
            <img
              src="/images/aselsan.png"
              alt="aselsanlogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/ticaret_borsası.png"
              alt="ticaretborsasılogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/sevda.png"
              alt="sevdakuruyemislogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/inox.png"
              alt="inoxparklogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/cebel.png"
              alt="cebellogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/anadolu_vakfı.png"
              alt="anadoluvakfilogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/tokay_kaucuk.png"
              alt="tokaykaucuklogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/biberci.png"
              alt="bibercilogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/tetik.png"
              alt="tetiklogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img src="/images/tcdd.png" alt="tcddlogo" className="slider-img" />
          </div>
          <div class="slide">
            <img
              src="/images/aselsan.png"
              alt="aselsanlogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/ticaret_borsası.png"
              alt="ticaretborsasılogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/sevda.png"
              alt="sevdakuruyemislogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/inox.png"
              alt="inoxparklogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/cebel.png"
              alt="cebellogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/anadolu_vakfı.png"
              alt="anadoluvakfilogo"
              className="slider-img"
            />
          </div>
          <div class="slide">
            <img
              src="/images/tokay_kaucuk.png"
              alt="tokaykaucuklogo"
              className="slider-img"
            />
          </div>
        </div>
      </div>
      {/* <!-- Logo Slider --> */}
    </>
  );
}
