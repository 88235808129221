import { useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  return (
    <>
      {/* <!-- Header Start --> */}
      <div class="container-fluid bg-dark px-0 header-all">
        <div class="row gx-0">
          <div class="col-lg-3 d-none d-lg-block header-logo">
            <a class="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
              <img src="images/logo.png" />
            </a>
          </div>
          <div class="col-lg-9">
            <div class="row gx-0 header-line d-none d-lg-flex">
              <div class="col-lg-7 px-5 text-start">
                <div class="h-100 d-inline-flex align-items-center py-2 me-4">
                  <i class="fa fa-envelope text-primary me-2"></i>
                  <p class="mb-0">info@konyahizmetvinc.com</p>
                </div>
                <div class="h-100 d-inline-flex align-items-center py-2">
                  <i class="fa fa-phone-alt text-primary me-2"></i>
                  <p class="mb-0">+90 530 637 1318</p>
                </div>
                <div class="h-100 d-inline-flex align-items-center py-2 ms-3">
                  <i class="fa fa-phone-alt text-primary me-2"></i>
                  <p class="mb-0">+90 532 509 0097</p>
                </div>
              </div>
              <div class="col-lg-5 px-5 text-end">
                <div class="d-inline-flex align-items-center py-2">
                  <a
                    class="me-3"
                    href="https://www.facebook.com/"
                    target="_blank"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a
                    class="me-3"
                    href="https://www.instagram.com/hizmetvincc/"
                    target="_blank"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a class="" href="https://www.youtube.com/" target="_blank">
                    <i class="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <nav class="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
              <a href="index.html" class="navbar-brand d-block d-lg-none">
                <img src="images/logo.png" />
              </a>
              <button
                type="button"
                class="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div
                class="collapse navbar-collapse justify-content-between"
                id="navbarCollapse"
              >
                <div class="navbar-nav mr-auto py-0">
                  <a
                    onClick={() => navigate('/')}
                    class="nav-item nav-link hover-cursor"
                  >
                    ANA SAYFA
                  </a>
                  <a
                    onClick={() => navigate('/hakkimizda')}
                    class="nav-item nav-link hover-cursor"
                  >
                    HAKKIMIZDA
                  </a>
                  <a
                    onClick={() => navigate('/hizmetler')}
                    class="nav-item nav-link hover-cursor"
                  >
                    HİZMETLERİMİZ
                  </a>
                  <a
                    onClick={() => navigate('/referanslar')}
                    class="nav-item nav-link hover-cursor"
                  >
                    REFERANSLARIMIZ
                  </a>
                  <a
                    onClick={() => navigate('/iletisim')}
                    class="nav-item nav-link hover-cursor"
                  >
                    İLETİŞİM
                  </a>
                </div>
                <a
                  onClick={() => navigate('/iletisim')}
                  class="btn btn-primary rounded-0 py-4 px-md-5 d-none d-lg-block hover-cursor"
                >
                  BİZE ULAŞIN<i class="fa fa-arrow-right ms-3"></i>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* <!-- Header End --> */}
    </>
  );
}
