export default function Contact() {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        class="container-fluid page-header p-0"
        style={{ backgroundImage: 'url(images/carousel-3.png)' }}
      >
        <div class="container-fluid page-header-inner py-5">
          <div class="container text-center pb-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">
              İletişim
            </h1>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Contact Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title text-center text-primary text-uppercase">
              İLETİŞİM
            </h6>
            <h1 class="mb-5"> Bizimle İletişime Geçin</h1>
          </div>
          <div class="row g-4">
            <div class="col-12">
              <div class="row gy-4">
                <div class="col-md-4">
                  <h6 class="section-title text-start text-primary text-uppercase">
                    E-Mail
                  </h6>
                  <p>
                    <i class="fa fa-envelope-open text-primary me-2"></i>
                    info@konyahizmetvinc.com
                  </p>
                </div>
                <div class="col-md-4">
                  <h6 class="section-title text-start text-primary text-uppercase">
                    GSM
                  </h6>
                  <p>
                    <i class="fa fa-phone text-primary me-2"></i>+90 530 637
                    1318
                  </p>
                </div>
                <div class="col-md-4">
                  <h6 class="section-title text-start text-primary text-uppercase">
                    GSM - 2
                  </h6>
                  <p>
                    <i class="fa fa-phone text-primary me-2"></i>+90 532 509
                    0097
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 wow fadeIn" data-wow-delay="0.1s"></div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}
    </>
  );
}
