export default function Reference() {
  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div
        class="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: 'url(images/carousel-2.png)' }}
      >
        <div class="container-fluid page-header-inner py-5">
          <div class="container text-center pb-5">
            <h1 class="display-3 text-white mb-3 animated slideInDown">
              Referanslarımız
            </h1>
          </div>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Room Start --> */}
      <div class="container-xxl py-5" id="service">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title text-center text-primary text-uppercase">
              REFERANSLARIMIZI
            </h6>
            <h1 class="mb-5">GÖRÜN</h1>
          </div>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/1.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/2.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/3.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-4 mt-3">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/4.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/5.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/6.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-4 mt-3">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/7.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/8.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="room-item shadow rounded overflow-hidden">
                <div class="position-relative">
                  <img class="img-fluid" src="images/9.png" alt="" />
                </div>
                <div class="p-4 mt-2">
                  <div class="d-flex justify-content-between mb-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Room End --> */}

      {/* <!-- Video Start --> */}
      {/* <div class="container-xxl py-5 px-0 wow zoomIn" data-wow-delay="0.1s">
        <div class="row g-0">
          <div class="col-md-6 bg-dark d-flex align-items-center">
            <div class="p-5">
              <h6 class="section-title text-start text-white text-uppercase mb-3">
                YAPIM SÜRECİ
              </h6>
              <h1 class="text-white mb-4">Referans Videomuz</h1>
              <p class="text-white mb-4">
                Müşterilerimizin memnuniyetini yansıtıyor. Estetik, dayanıklılık
                ve profesyonel hizmetlerimiz hakkında gerçek müşteri deneyimleri
                bu videoda yer alıyor. Moda Metal Cam Balkon Sistemleri'ne neden
                güvendiklerini keşfedin.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <iframe
              style={{ width: '100%', height: '100%' }}
              type="button"
              class="btn-play w-100 h-100"
              src="https://www.youtube.com/embed/8eEX0Tjj9fo?si=KVk7MqidLCaBhm4W"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div> */}

      <div
        class="modal fade"
        id="videoModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content rounded-0">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Youtube Video
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {/* <!-- 16:9 aspect ratio --> */}
              <div class="ratio ratio-16x9">
                <iframe
                  class="embed-responsive-item"
                  src="https://www.youtube.com/shorts/EoMfljnXLNc"
                  id="video"
                  allowfullscreen
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Video Start --> */}
    </>
  );
}
