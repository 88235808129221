export default function Footer() {
  return (
    <>
      {/* <!-- Footer Start --> */}
      <div
        class="container-fluid bg-dark text-light wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div class="container">
          <div class="g-5 footerFlex" style={{ display: 'flex' }}>
            <div class="col-md-6 col-lg-3 py-5">
              <h6 class="section-title text-start text-primary text-uppercase mb-4">
                İLETİŞİM
              </h6>
              <p class="mb-2">
                <i class="fa fa-map-marker-alt me-3"></i>Fevzi Çakmak Mh. 10568.
                Sk. No:1 Karatay/KONYA
              </p>
              <p class="mb-2">
                <i class="fa fa-phone-alt me-3"></i>+90 530 637 1318
              </p>
              <p class="mb-2">
                <i class="fa fa-phone-alt me-3"></i>+90 532 509 0097
              </p>
              <p class="mb-2">
                <i class="fa fa-envelope me-3"></i>info@konyahizmetvinc.com
              </p>
              <div class="d-flex pt-2">
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.instagram.com/hizmetvincc/"
                  target="_blank"
                >
                  <i class="fab fa-instagram"></i>
                </a>
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.youtube.com/"
                >
                  <i class="fab fa-youtube"></i>
                </a>
              </div>
            </div>
            <div class="col-lg-9 h-100 ">
              <div class="py-3 px-5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2646.8382270349684!2d32.53133441772343!3d37.91550332676931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d08fda530828c5%3A0xd3b17de0300410f1!2sHizmet%20Vin%C3%A7%20%7C%20Konya%20Vin%C3%A7%20Kiralama!5e0!3m2!1str!2str!4v1709731178830!5m2!1str!2str"
                  width="100%"
                  height="300"
                  style={{
                    borderRadius: '25px',
                    boxShadow:
                      'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;',
                  }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="copyright">
            <div class="row">
              <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; 2023{' '}
                <a class="border-bottom" href="#">
                  Hizmet Vinç
                </a>
                , Tüm Hakları Saklıdır.
              </div>
              <div class="col-md-6 text-center text-md-end">
                <div class="footer-menu">
                  Yazılım:{' '}
                  <a href="https://www.instagram.com/mkrsoft/" target="_blank">
                    MkrSoft Yazılım
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}
    </>
  );
}
